export default {
  environment: 'prod',
  agentGatewayUrl: 'https://end-gateway-agents-prod.azurewebsites.net/api',
  auth: {
    b2c: {
      signInName: 'B2C_1A_SIGNUP_SIGNIN',
      signInAuthority: 'https://edsb2c.b2clogin.com/edsb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
      authorityDomain: 'edsb2c.b2clogin.com',
    },
    loginEndpoint: 'https://edsb2c.onmicrosoft.com/end-gateway-agents-prod/agent',
    msal: {
      clientId: 'e254dc93-850d-4e1f-9e16-9d627739cc5e',
      tenantId: '1cd50b92-031a-41a6-885e-0089bea3c789',
    },
  },
  idleTimeoutInMinutes: 30,
};
