import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import config from '../config';

export const UserActivityContext = React.createContext({
  isUserIdle: false,
  emitUserAction: () => {},
});

export const UserActivityProvider = ({ children }) => {
  const [isUserIdle, setIsUserIdle] = useState(false);

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * config.idleTimeoutInMinutes,
    onIdle: () => {
      setIsUserIdle(true);
    },
  });

  const emitUserAction = () => {
    reset();
  };

  return (
    <UserActivityContext.Provider value={{ isUserIdle, emitUserAction }}>
      {children}
    </UserActivityContext.Provider>
  );
};
