import React, { useContext } from 'react';
import {
  useMsal,
  useMsalAuthentication,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { UserActivityContext } from './contexts/UserActivityContext';
import useCurrentUser from './hooks/useCurrentUser';
import NavBar from './components/NavBar/NavBar';
import ReportPane from './components/ReportPane/ReportPane';
import endIcon from './images/end-icon.png';

import './App.css';

function App() {
  const { instance } = useMsal();
  const { login, error } = useMsalAuthentication('redirect');
  const { isUserIdle } = useContext(UserActivityContext);
  const [currentUser, isInProgress] = useCurrentUser();

  // If there is an error with the redirect MSAL login, force a login.
  if (error) {
    login();
  }

  if (isUserIdle) {
    instance.logoutRedirect();
  }

  const accessNeededWarning = (
    <div className="accessNeeded">
      <img src={endIcon} alt="Endurance Icon" />
      <h2>
        Your account does not have access to this portal.
        <br />
        Please allow up to 24 hours for access to be granted to new accounts.
      </h2>
      <h3>If this problem persists, please contact us at (866) 690-6665.</h3>
    </div>
  );

  const logInMessage = <div className="loggingIn">Signing you in...</div>;

  return (
    <div className="appRoot">
      <div className="header">
        <NavBar />
      </div>
      <div className="content">
        <AuthenticatedTemplate>
          {!isInProgress && currentUser.getRole() === 'Unknown' ? (
            accessNeededWarning
          ) : (
            <ReportPane />
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>{logInMessage}</UnauthenticatedTemplate>
      </div>
    </div>
  );
}

export default App;
