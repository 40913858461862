import * as React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import useCurrentUser from '../../hooks/useCurrentUser';
import { logout } from '../../services/AuthService';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import edsLogo from '../../images/eds-logo.png';

import './NavBar.css';

function NavBar() {
  const isAuthenticated = useIsAuthenticated();
  const [currentUser, inProgress] = useCurrentUser();

  const userDetails = (
    <div className="userDetails">
      <div className="pure-menu pure-menu-horizontal">
        <ul className="pure-menu-list">
          <li className="feedbackLink pure-menu-item">
            <a
              href="mailto:feedback-edge@endurancedirect.com?subject=EDGE%20Feedback"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon className="separator" icon={faEnvelope} />
              Feedback? Email <i>feedback-edge@endurancedirect.com</i>
            </a>
          </li>
          <li className="pure-menu-item pure-menu-has-children pure-menu-allow-hover">
            <a className="userMenu pure-menu-link">{currentUser && currentUser.name}</a>
            <ul className="menuOptions pure-menu-children">
              <li className="pure-menu-item">
                <a className="pure-menu-link" onClick={logout}>
                  Log Out
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <div className="navBarRoot">
      <img src={edsLogo} alt="EDS Logo" />
      <h1>Edge</h1>
      {inProgress && <LoadingSpinner />}
      {isAuthenticated && currentUser && userDetails}
    </div>
  );
}

export default NavBar;
