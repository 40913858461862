/* @flow */
import { useState, useEffect } from 'react';
import { getEmbeddedReport } from '../services/AgentApi';
import type { $UseEmbeddedReportModel } from '../models/UseEmbeddedReportModel';
import type { $ReportMetadataModel } from '../models/UseMyReportsModel';

function useEmbeddedReport(reportMetadata: $ReportMetadataModel): $UseEmbeddedReportModel {
  const [report, setReport] = useState(null);
  const [error, setError] = useState(null);

  const loadEmbeddedReport = async () => {
    try {
      const embeddedReport = reportMetadata && (await getEmbeddedReport(reportMetadata.id));

      setReport(embeddedReport);
      setError(null);
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    loadEmbeddedReport();
  }, [reportMetadata && reportMetadata.id]);

  return {
    report,
    error,
  };
}

export default useEmbeddedReport;
