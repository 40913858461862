/* @flow */

export function formatDateForDateSlicer(date: Date): number {
  if (!Date) {
    return -1;
  }

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString();

  const dateString = `${year}${month}${day}`;

  return Number.parseInt(dateString);
}
