/* @flow */
import { useState, useEffect } from 'react';
import type { $UseMyReportsModel } from '../models/UseMyReportsModel';
import { getMyReports } from '../services/AgentApi';

function useMyReports(): $UseMyReportsModel {
  const [myReports, setMyReports] = useState(null);
  const [error, setError] = useState(null);

  const loadMyReports = async () => {
    try {
      const allReports = await getMyReports();

      setMyReports({
        reportMetadata: allReports,
        selectedReport: allReports.length > 0 ? allReports[0] : null,
      });

      setError(null);
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    loadMyReports();
  }, []);

  const setSelectedReport = (report) => {
    setMyReports({
      ...myReports,
      selectedReport: report,
    });
  };

  return {
    allReports: (myReports && myReports.reportMetadata) || [],
    selectedReport: myReports && myReports.selectedReport,
    setSelectedReport,
    error,
  };
}

export default useMyReports;
