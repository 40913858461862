/* @flow */
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './ReportNavigation.css';

type PowerBIPage = {
  displayName: string,
  name: string,
};

type Props = {
  breadcrumb: PowerBIPage[],
  currentPage: ?PowerBIPage,
  onNavigation: (page: PowerBIPage) => void,
};

function ReportNavigation({ breadcrumb, currentPage, onNavigation }: Props): React$Element<any> {
  if (!breadcrumb || breadcrumb.length === 0) {
    return <></>;
  }

  return (
    <div className="reportNavigationRoot">
      {breadcrumb.map((page) => (
        <React.Fragment key={page.name}>
          <a onClick={() => onNavigation(page)}>{page.displayName}</a>
          <FontAwesomeIcon className="separator" icon={faChevronRight} />
        </React.Fragment>
      ))}
      <span>{currentPage && currentPage.displayName}</span>
    </div>
  );
}

export default ReportNavigation;
