/* @flow */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { formatDateForDateSlicer } from '../../utils/formatters';
import ReportNavigation from '../ReportNavigation/ReportNavigation';
import ReportSlicer from '../ReportSlicer/ReportSlicer';
import useEmbeddedReport from '../../hooks/useEmbeddedReport';
import { UserActivityContext } from '../../contexts/UserActivityContext';
import type { $ReportMetadataModel } from '../../models/UseMyReportsModel';
import endIcon from '../../images/end-icon.png';

import './EmbeddedReport.css';

type Props = {
  reportMetadata: $ReportMetadataModel,
};

// Report date slicers, set to (3 complete months ago - today)
const initialToDate = new Date();
const initialFromDate = new Date(initialToDate);
initialFromDate.setDate(1);
initialFromDate.setMonth(initialFromDate.getMonth() - 3);

function EmbeddedReport({ reportMetadata }: Props): React$Element<any> {
  const [currentPage, setCurrentPage] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState([]);

  const [isReportRendered, setIsReportRendered] = useState(false);
  const [isPageRendered, setIsPageRendered] = useState(false);

  const { report, error } = useEmbeddedReport(reportMetadata);
  const { emitUserAction } = useContext(UserActivityContext);

  const [toSlicer, setToSlicer] = useState(initialToDate);
  const [fromSlicer, setFromSlicer] = useState(initialFromDate);

  useEffect(() => {
    setIsReportRendered(false);
    setCurrentPage(null);
    setBreadcrumb([]);
  }, [reportMetadata && reportMetadata.id]);

  /* 
    In order to access our state vars within the PowerBI callbacks (non-react components),
    we need to store a reference.
  */
  const reportMetadataRef = useRef();
  const currentPageRef = useRef();
  const breadcrumbRef = useRef();

  reportMetadataRef.current = reportMetadata;
  currentPageRef.current = currentPage;
  breadcrumbRef.current = breadcrumb;

  const onRendered = () => {
    setIsReportRendered(true);
    setIsPageRendered(true);
  };

  const onPageNavigation = (targetPage) => {
    setIsPageRendered(false);

    const currentPageValue = currentPageRef.current;
    const breadcrumbValue = breadcrumbRef.current || [];

    if (!currentPageValue || currentPageValue.name === targetPage.name) {
      setCurrentPage(targetPage);

      return;
    }

    if (breadcrumbValue.some((page) => page.name === targetPage.name)) {
      const backwardNavBreadcrumb = breadcrumbValue.slice(
        0,
        breadcrumbValue.findIndex((page) => page.name === targetPage.name),
      );

      setBreadcrumb(backwardNavBreadcrumb);
    } else {
      const forwardNavBreadcrumb = breadcrumbValue.concat([currentPageValue]);

      setBreadcrumb(forwardNavBreadcrumb);
    }

    setCurrentPage(targetPage);
  };

  const onDataPointSelected = async (dataPoint) => {
    const reportMetadataValue = reportMetadataRef.current;

    if (
      !reportMetadataValue ||
      !reportMetadataValue.drillthrough ||
      !dataPoint ||
      !dataPoint.identity
    ) {
      return;
    }

    const identity = dataPoint.identity.filter(
      (id) => id.target.column in reportMetadataValue.drillthrough,
    )[0];

    if (!identity) {
      return;
    }

    const { target, equals } = identity;
    const { column } = target;

    const drillthroughTarget = reportMetadataValue.drillthrough[column];

    if (!drillthroughTarget) {
      return;
    }

    setIsPageRendered(false);

    const drillthroughFilter = {
      target: { ...drillthroughTarget.filter },
      filterType: models.FilterType.Basic,
      operator: 'In',
      values: [equals],
    };

    const targetPage = await window.report.getPageByName(drillthroughTarget.page);

    onPageNavigation(targetPage);

    await targetPage.updateFilters(models.FiltersOperations.Replace, [drillthroughFilter]);
    await window.report.setPage(drillthroughTarget.page);
  };

  const onDateRangeSlicerChanged = async ({ from, to }) => {
    setFromSlicer(from);
    setToSlicer(to);

    const activePage = await window.report.getActivePage();
    const slicers = await activePage.getSlicers();

    const slicersWithState = await Promise.all(
      slicers.map(async (slicer) => ({
        slicer: slicer,
        state: await slicer.getSlicerState(),
      })),
    );

    const slicersToUpdate = slicersWithState
      .filter((details) => {
        if (!details || !details.state || !details.state.targets) {
          return false;
        }

        return details.state.targets.some(
          (target) => target.table === 'DateDim' && target.column === 'DateKey',
        );
      })
      .map((slicerWithState) => slicerWithState.slicer);

    const newSlicerState = buildDateSlicerStateConfig(from, to);

    await Promise.all(slicersToUpdate.map((slicer) => slicer.setSlicerState(newSlicerState)));
  };

  const powerBiEmbed = (
    <PowerBIEmbed
      embedConfig={buildEmbedConfig(report, reportMetadata, fromSlicer, toSlicer)}
      eventHandlers={
        new Map([
          [
            'error',
            function (event) {
              console.error(event.detail);
            },
          ],
          ['rendered', onRendered],
          [
            'pageChanged',
            (pageEvent) => {
              onPageNavigation(pageEvent.detail.newPage);
              emitUserAction();
            },
          ],
          ['visualClicked', emitUserAction],
          [
            'dataSelected',
            async (selectEvent) => {
              await onDataPointSelected(selectEvent.detail.dataPoints[0]);
              emitUserAction();
            },
          ],
        ])
      }
      cssClassName={'embeddedReport'}
      getEmbeddedComponent={(embeddedReport) => {
        window.report = embeddedReport;
      }}
    />
  );

  const renderingOverlay = (
    <div className="overlay">
      <img src={endIcon} alt="Endurance Icon" />
    </div>
  );

  return (
    <div className="embeddedReportRoot">
      {error ? (
        <div className="reportError">
          <h2>We could not load your report. Please refresh this page and try again.</h2>
          <code>For developers: {error.message}</code>
        </div>
      ) : (
        <div className="reportContainer">
          {currentPage && isReportRendered && (
            <ReportNavigation
              breadcrumb={breadcrumb}
              currentPage={currentPage}
              onNavigation={(page) => window.report.setPage(page.name)}
            />
          )}
          <div className="reportContent">
            <ReportSlicer
              dateRange={{ to: toSlicer, from: fromSlicer }}
              onDateRangeSelected={onDateRangeSlicerChanged}
            />
            {(!isReportRendered || !isPageRendered) && renderingOverlay}
            {powerBiEmbed}
          </div>
        </div>
      )}
    </div>
  );
}

/*
  For configuration options, see:
  https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/configure-report-settings
*/
const buildEmbedConfig = (report, reportMetadata, from, to) => {
  return {
    type: (report && report.type) || 'report',
    id: report && report.id,
    embedUrl: report && report.embedUrl,
    accessToken: report && report.accessToken,
    tokenType: models.TokenType.Embed,
    pageName: reportMetadata && reportMetadata.defaultPage,
    slicers: [buildDateSlicerConfig(from, to)],
    settings: {
      panes: {
        filters: {
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
      commands:
        reportMetadata &&
        reportMetadata.commands &&
        reportMetadata.commands.map((c) => ({
          [c.command]: {
            displayOption: c.isHidden
              ? models.CommandDisplayOption.Hidden
              : models.CommandDisplayOption.Enabled,
          },
        })),
      filters:
        reportMetadata &&
        reportMetadata.filters &&
        reportMetadata.filters.map((f) => ({
          target: {
            table: f.table,
            column: f.column,
          },
          filterType: models.FilterType.Basic,
          operator: 'In',
          values: [f.value],
        })),
    },
  };
};

const buildDateSlicerConfig = (from, to) => {
  return {
    selector: {
      $schema: 'http://powerbi.com/product/schema#slicerTargetSelector', // library requires this
      target: {
        column: 'DateKey',
        table: 'DateDim',
      },
    },
    state: buildDateSlicerStateConfig(from, to),
  };
};

const buildDateSlicerStateConfig = (from, to) => {
  return {
    filters: [
      {
        target: {
          table: 'DateDim',
          column: 'DateKey',
        },
        logicalOperator: 'And',
        conditions: [
          {
            operator: 'GreaterThanOrEqual',
            value: formatDateForDateSlicer(from),
          },
          {
            operator: 'LessThanOrEqual',
            value: formatDateForDateSlicer(to),
          },
        ],
        filterType: models.FilterType.Advanced,
      },
    ],
  };
};

export default EmbeddedReport;
