import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './LoadingSpinner.css';

function LoadingSpinner() {
  return (
    <div className="loadingSpinnerRoot">
      <FontAwesomeIcon icon={faSpinner} />
    </div>
  );
}

export default LoadingSpinner;
