/* @flow */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import DayPicker, { DateUtils } from 'react-day-picker/DayPicker';

import 'react-day-picker/lib/style.css';
import './ReportSlicer.css';

// Day Picker config
const numberOfMonths = 2;

// Preset time periods
const now = new Date();
const timePeriods = [
  {
    title: 'Last Week',
    range: {
      from: (() => {
        const d = new Date(now);
        d.setDate(d.getDate() - 7);
        return d;
      })(),
      to: now,
    },
  },
  {
    title: 'Month-to-Date',
    range: {
      from: (() => {
        const d = new Date(now);
        d.setDate(1);
        return d;
      })(),
      to: now,
    },
  },
  {
    title: 'Previous Month',
    range: {
      from: (() => {
        const d = new Date(now);
        d.setDate(1);
        d.setMonth(d.getMonth() - 1);
        return d;
      })(),
      to: (() => {
        const d = new Date(now);
        d.setDate(0);
        return d;
      })(),
    },
  },
  {
    title: 'Last 3 Months',
    range: {
      from: (() => {
        const d = new Date(now);
        d.setDate(1);
        d.setMonth(d.getMonth() - 3);
        return d;
      })(),
      to: now,
    },
  },
  {
    title: 'Year-to-Date',
    range: {
      from: (() => {
        const d = new Date(now);
        d.setMonth(0);
        d.setDate(1);
        return d;
      })(),
      to: now,
    },
  },
];

type Props = {
  dateRange: { from: Date, to: Date },
  onDateRangeSelected: ({ from: Date, to: Date }) => any,
};

function ReportSlicer({ dateRange, onDateRangeSelected }: Props): React$Element<any> {
  const [range, setRange] = useState({ from: null, to: null });
  const [showDropdown, setShowDropdown] = useState(false);

  const editDateRange = () => {
    setRange({ from: null, to: null });
    setShowDropdown(true);
  };

  const confirmDateRange = () => {
    if (range.from && range.to) {
      onDateRangeSelected(range);
      setShowDropdown(false);
    }
  };

  const onDaySelected = (day) => {
    const updatedRange = DateUtils.addDayToRange(day, range);
    setRange(updatedRange);
  };

  return (
    <div className="reportSlicerRoot">
      <div className="slicerHeader">
        <FontAwesomeIcon className="separator" icon={faCalendar} />
        <span className="range">
          {dateRange.from.toLocaleDateString()} - {dateRange.to.toLocaleDateString()}
        </span>
        {showDropdown ? (
          <a onClick={() => setShowDropdown(false)}>Cancel</a>
        ) : (
          <a onClick={editDateRange}>Change</a>
        )}
      </div>
      {showDropdown && (
        <div className="slicerDropdown">
          <div className="actionList">
            {timePeriods.map((period, i) => (
              <button key={i} onClick={() => setRange(period.range)}>
                {period.title}
              </button>
            ))}
            <div className="confirm">
              <button onClick={confirmDateRange}>Confirm</button>
            </div>
          </div>
          <DayPicker
            className="Selectable"
            month={new Date(now.getFullYear(), now.getMonth() - 1)}
            numberOfMonths={numberOfMonths}
            selectedDays={[range.from, range]}
            modifiers={{ start: range.from, end: range.to }}
            onDayClick={onDaySelected}
          />
        </div>
      )}
    </div>
  );
}

export default ReportSlicer;
