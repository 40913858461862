import { useMsal, useAccount } from '@azure/msal-react';

function useCurrentUser() {
  const { accounts, inProgress } = useMsal();
  const currentUser = useAccount(accounts[0] || {}) || {};

  const isInProgress = inProgress !== 'none';

  currentUser.getRole = () =>
    (currentUser && currentUser.idTokenClaims && currentUser.idTokenClaims.Role) || 'Unknown';

  return [currentUser, isInProgress];
}

export default useCurrentUser;
