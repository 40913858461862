import * as React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import EmbeddedReport from '../../components/EmbeddedReport/EmbeddedReport';
import useMyReports from '../../hooks/useMyReports';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import './ReportPane.css';

function ReportPane() {
  const { selectedReport, setSelectedReport, allReports, error } = useMyReports();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  let sidebarItems =
    allReports &&
    allReports.length > 0 &&
    allReports.map((report) => (
      <div
        className={`sidebarItem${report.id === selectedReport.id ? ' selected' : ''}`}
        key={report.id}
        onClick={() => {
          setSelectedReport(report);
        }}
      >
        <FontAwesomeIcon icon={faIcons[report.iconName]} />
        <h3>{report.displayName}</h3>
      </div>
    ));

  const sidebar = isSidebarCollapsed ? (
    <div className="sidebar collapsed">
      <div className="toggle" onClick={() => setIsSidebarCollapsed(false)}>
        <FontAwesomeIcon icon={faIcons.faChevronRight} />
      </div>
      <h2>Reports</h2>
    </div>
  ) : (
    <div className="sidebar expanded">
      <div className="toggle" onClick={() => setIsSidebarCollapsed(true)}>
        <FontAwesomeIcon icon={faIcons.faChevronLeft} />
        <h2>Reports</h2>
        {(!allReports || allReports.length === 0) && (
          <div className="loading">
            <LoadingSpinner />
          </div>
        )}
      </div>
      {sidebarItems}
    </div>
  );

  return (
    <div className="reportPaneRoot">
      {error ? (
        <div className="reportError">
          <h2>We could not load your reports. Please refresh this page and try again.</h2>
          <code>For developers: {error.message}</code>
        </div>
      ) : (
        <>
          {sidebar}
          <div className="content">
            <EmbeddedReport reportMetadata={selectedReport} />
          </div>
        </>
      )}
    </div>
  );
}

export default ReportPane;
