import { PublicClientApplication } from '@azure/msal-browser';
import config from '../config';

const b2cPolicies = {
  names: {
    signIn: config.auth.b2c.signInName,
  },
  authorities: {
    signIn: {
      authority: config.auth.b2c.signInAuthority,
    },
  },
  authorityDomain: config.auth.b2c.authorityDomain,
};

const loginScope = config.auth.loginEndpoint;

// sample: https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial
const msalConfig = {
  auth: {
    clientId: config.auth.msal.clientId,
    authority: b2cPolicies.authorities.signIn.authority,
    tenantId: config.auth.msal.tenantId,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const authClient = new PublicClientApplication(msalConfig);

export const acquireTokenAsync = async () => {
  const account = authClient.getAllAccounts()[0];
  const tokenResponse = await authClient.acquireTokenSilent({
    account: account,
    scopes: [loginScope, 'offline_access'],
  });

  return tokenResponse;
};

export const logout = () => {
  authClient.logoutRedirect();
};
