/* @flow */
import axios from 'axios';
import config from '../config';
import { acquireTokenAsync } from './AuthService';
import type { $ReportMetadataModel } from '../models/UseMyReportsModel';
import type { $EmbeddedReportModel } from '../models/UseEmbeddedReportModel';

export const getMyReports = async (): Promise<$ReportMetadataModel[]> => {
  const token = await acquireTokenAsync();
  const bearer = `Bearer ${token.accessToken}`;

  const response = await axios.get(`${config.agentGatewayUrl}/v1/reports/by-user-role`, {
    headers: {
      Authorization: bearer,
    },
  });

  return (response.data && response.data.reports: $ReportMetadataModel[]);
};

export const getEmbeddedReport = async (reportId: string): Promise<$EmbeddedReportModel> => {
  const token = await acquireTokenAsync();
  const bearer = `Bearer ${token.accessToken}`;

  const response = await axios.get(`${config.agentGatewayUrl}/v1/reports/${reportId}`, {
    headers: {
      Authorization: bearer,
    },
  });

  return (response.data: $EmbeddedReportModel);
};
